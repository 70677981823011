import { TextField } from '@mui/material'
import { useEffect, useState } from 'react'

type Props = {
  index: number
  value: string
  setValue: (value: string) => void
  labelPrefix: string
}

export const NameField = ({ index, value, setValue, labelPrefix }: Props) => {
  const [localName, setLocalName] = useState(value)

  useEffect(() => {
    setLocalName(value)
  }, [value])

  return (
    <TextField
      fullWidth
      variant="outlined"
      name={`${labelPrefix}-${index + 1}-name`}
      value={localName}
      label={`${labelPrefix} ${index + 1} Name`}
      onChange={({ target }) => setLocalName(target.value)}
      onBlur={() => setValue(localName)}
      required
    />
  )
}
